<template>
  <b-row>
    <b-col cols="3">
      <form-setting v-on:demoUpdated="refreshPreview" />
    </b-col>
    <b-col cols="9">
      <form-preview ref="formPreview" :setting-id="colorSettingId" :reload-hash="reloadHash" :form-type="formType" />
    </b-col>
  </b-row>
</template>

<script>
import FormSetting from "@/view/pages/form-designer/FormSetting.vue";
import FormPreview from "@/view/pages/form-designer/FormPreview.vue";
import generateHash from 'random-hash';

export default {
  data() {
    return {
      colorSettingId: "",
      reloadHash: "1",
      formType: "pmi"
    }
  },
  name: "form-designer",
  components: {
    FormPreview,
    FormSetting
  },
  methods: {
    refreshPreview: function(params){
      console.log('PREVIEWS:'+JSON.stringify(params));
      this.colorSettingId = params.demoSettingId;
      this.formType = params.formType;
      this.reloadHash = generateHash();
    }
  }
}
</script>

<style>

</style>