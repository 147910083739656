<template>
  <div class="mb-3">
    <color-picker :color="color" :onEndChange="updateColor"></color-picker>
    <div class="pallete-container d-flex justify-content-between mt-5">
      <div v-for="(shade, i) in shades" class="color-pallete align-self-center" v-bind:class="{ active: i == activeIndex }" :key="i" v-bind:style="{ backgroundColor: shade }" @click="updateSelected(shade, i)"></div>
    </div>
  </div>
  
</template>

<script>
import { ColorPicker } from 'vue-color-gradient-picker';
const convert = require('color-convert');
const tinycolor = require("tinycolor2");

export default {
  name: "custom-colorpicker",
  components: {
    "color-picker": ColorPicker
  },
  props: ["value"],
  data() {
    return {
      palleteActive: false,
      palleteHex: "#ACACAC",
      activeIndex: 3,
    }
  },
  computed: {
    selectedHex: {
      get() {
        return this.value;  
      },
      set(value) {
        this.$emit("colorUpdate", value);
        this.$emit("input", value);
      }
    },
    color: {
      get() {
        return this.convertToRgb(this.selectedHex);
      }
    },
    shades: {
      get(){
        var tetrad = tinycolor(this.palleteHex).tetrad();
        var splitComplement = tinycolor(this.palleteHex).splitcomplement();
        var triad = tinycolor(this.palleteHex).triad();
        var analogous = tinycolor(this.palleteHex).analogous();

        var colors = [];
        colors = colors.concat(analogous, splitComplement, triad, tetrad);

        return colors.map(t => t.toHexString());
      }   
    }
  },
  methods: {
    updateColor: function(color) {
      var hex = "#"+convert.rgb.hex(color.red, color.green, color.blue);

      this.palleteHex = hex;
      this.selectedHex = hex;
    },
    selectColor: function(){

    },
    convertToRgb: function(hex){
      var rgb = convert.hex.rgb(hex);
      
      return {
        red: rgb[0],
        green: rgb[1],
        blue: rgb[2],
        alpha: 1
      }
    },
    convertToHsl: function(hex){
      var hsl = convert.hex.hsl(hex);
      
      return {
        hue: hsl[0],
        saturation: hsl[1],
        luminosity: hsl[2],
      }
    },
    updateSelected: function(hex, colorIndex){
      this.activeIndex = colorIndex;
      this.selectedHex = hex;
    }
  },
  mounted() {
    setTimeout(() =>{
      this.palleteHex = this.selectedHex;
    }, 200);
  }
}
</script>

<style lang="scss">
@import '~vue-color-gradient-picker/dist/index.css';

.pallete-container  {
  height: 23px;
}

.color-pallete {
  width: 15px;
  height: 15px;
  border-radius: 0.3rem;
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
  cursor: pointer;
  border: solid 1px transparent;

  &.white {
    background-color: #ffffff;
  }

  &.black {
    background-color: #000000;
  }

  &.active {
    // height: 23px;
    border-color: #000000;
  }
}

.ui-color-picker {
  margin: 0;

  .color-preview-area {
    padding: 0 !important;

    .input-group > div:last-child {
      display: none;
    }

    .label {
      background-color: transparent;
      text-transform: uppercase;
      color: #3F4254;
    }

    .hex {
      .text-input {
        color: #3F4254;
      }
    }

    .rgb {
      width: 60px;

      .number-input {
        color: #3F4254;
      }
      
    }
  }

  .alpha {
    display: none;
  }
}

.picker-area {
  padding: 0 !important;
}


</style>